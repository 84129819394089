import L_Handle_set from "../img/L_Handle_set.png";
import Fat_Grip_For_Cupping from "../img/Fat_Grip_For_Cupping.png";
import Cone_Grip from "../img/Cone_Grip.png";
import Wrist_Wrench from "../img/Wrist_Wrench.png";
import Cupping_Handle from "../img/Cupping_Handle.png";
import Multi_Spinner from "../img/Multi_Spinner.png";
import TRINGA_ECCENTRIC_SET from "../img/TRINGA_ECCENTRIC_SET.png";
import Palm_Cupping_L_Handle from "../img/Palm_Cupping_L_Handle.png";
import Loading_Pin from "../img/Loading_Pin.png";
import Hand_Winder from "../img/Hand_Winder.png";
import Tiranga_Eccentric_Grip from "../img/Tiranga_Eccentric_Grip.png";
import Elliptical_Wrist_Ball from "../img/Elliptical_Wrist_Ball.png";
import Premium_Table_Strap from "../img/Premium_Table_Strap.png";
import Eccentric_Grip from "../img/Eccentric_Grip.png";
import Professional_Wrist_Ball from "../img/Professional_Wrist_Ball.png"; 
import img from "../img/table.jpg"
const apiUrl = process.env.REACT_APP_API_URL;

const Categories=[
    {
        id:1,
        title:"Wrestling Table",
        price:11999.00,
        discription:"Can be assembled and carried in any car, easily transportable",
        image: img,
        offer:20,
    },
    {
        id:2,
        title:"Grip Set",
        price:4799.00,
        discription:"Can be assembled and carried in any car, easily transportable",
        image: L_Handle_set,
        offer:20,
    },
    {
        id:3,
        title:"Fat Grip",
        price:799.00,
        discription:"Can be assembled and carried in any car, easily transportable",
        image: Fat_Grip_For_Cupping,
        offer:25,
    },
    {
        id:4,
        title:"handle Set",
        price:1499.00,
        discription:"Can be assembled and carried in any car, easily transportable",
        image: Wrist_Wrench,
        offer:20,
    },
    // {
    //     id:5,
    //     title:"Fat Grip",
    //     price:231,
    //     discription:"Can be assembled and carried in any car, easily transportable",
    //     image: Cone_Grip,
    //     offer:25,
    // },
    {
        id:6,
        title:"Strap",
        price:199.00,
        discription:"Can be assembled and carried in any car, easily transportable",
        image: Multi_Spinner,
        offer:20,
    },
    // {
    //     id:7,
    //     title:"handle Set",
    //     price:2799.00,
    //     discription:"Can be assembled and carried in any car, easily transportable",
    //     image: Cupping_Handle,
    //     offer:20,
    // },
    {
        id:8,
        title:"Tiranga Eccentric Grip",
        price:4999.00,
        discription:"Can be assembled and carried in any car, easily transportable",
        image: TRINGA_ECCENTRIC_SET,
        offer:25,
    },
    // {
    //     id:9,
    //     title:"Grip Set",
    //     price:1499.00,
    //     discription:"Can be assembled and carried in any car, easily transportable",
    //     image: Palm_Cupping_L_Handle,
    //     offer:20,
    // },
    // {
    //     id:10,
    //     title:"Fat Grip",
    //     price:231,
    //     discription:"Can be assembled and carried in any car, easily transportable",
    //     image: Loading_Pin,
    //     offer:25,
    // },
    {
        id:11,
        title:"Tiranga Eccentric Grip",
        price:2499.00,
        discription:"Can be assembled and carried in any car, easily transportable",
        image: Tiranga_Eccentric_Grip,
        offer:20,
    },
    {
        id:12,
        title:"Elliptical Wristball",
        price:599.00,
        discription:"Can be assembled and carried in any car, easily transportable",
        image: Elliptical_Wrist_Ball,
        offer:20,
    },
    {
        id:13,
        title:"Training Strap",
        price:199.00,
        discription:"Can be assembled and carried in any car, easily transportable",
        image: Premium_Table_Strap,
        offer:25,
    },
    {
        id:14,
        title:"Grip Set",
        price:399.00,
        discription:"Can be assembled and carried in any car, easily transportable",
        image: Eccentric_Grip,
        offer:20,
    },
    {
        id:15,
        title:"Wristball",
        price:499.00,
        discription:"Can be assembled and carried in any car, easily transportable",
        image: Professional_Wrist_Ball,
        offer:25,
    },
     
]

export default Categories
 