import { Heart, Trash } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import '../styles/tailwind.css';
import { Link } from 'react-router-dom';
const apiUrl = process.env.REACT_APP_API_URL;

const MyCart = () => {
  const [cartData, setCartData] = useState([]);
  const [subtotal, setSubtotal] = useState(0);
  const [itemCount, setItemCount] = useState(0);

  const updateCartData = (newCartData) => {
    setCartData(newCartData);
    const total = newCartData.reduce((acc, item) => acc + item.price * item.quantity, 0);
    setSubtotal(total);
    setItemCount(newCartData.length);
    localStorage.setItem('cartData', JSON.stringify(newCartData));
  };

  useEffect(() => {
    const getCartFromCart = () => {
      const storedCartData = localStorage.getItem('cartData');
      try {
        const parsedData = JSON.parse(storedCartData);
        if (Array.isArray(parsedData)) {
          updateCartData(parsedData);
        } else {
          updateCartData([]);
        }
      } catch (error) {
        console.error('Failed to parse stored cart data:', error);
        updateCartData([]);
      }
    };
    getCartFromCart();
  }, []);

  const removeCart = () => {
    localStorage.removeItem('cartData');
    updateCartData([]);
    alert('All items have been removed from the cart.');
  };

  const handleRemoveItem = (id) => {
    const updatedCart = cartData.filter((item) => item._id !== id);
    updateCartData(updatedCart);
    alert('Item has been removed from the cart.');
  };

  const updateCartQuantity = (product, increment) => {
    const cartData = localStorage.getItem('cartData');
    let cart = cartData ? JSON.parse(cartData) : [];
    const existingProductIndex = cart.findIndex((item) => item.name === product.name);

    if (existingProductIndex !== -1) {
      const newQuantity = (cart[existingProductIndex].quantity || 1) + increment;
      if (newQuantity <= 0) {
        cart.splice(existingProductIndex, 1);
      } else {
        cart[existingProductIndex].quantity = newQuantity;
      }
    }
    localStorage.setItem('cartData', JSON.stringify(cart));
    updateCartData(cart);
    alert(increment > 0 ? 'Product added to cart!' : 'Product removed from cart!');
  };

  return (
    <div className="max-w-7xl px-2 lg:px-4 mx-auto pt-[110px]">
      <div className="max-w-2xl lg:max-w-7xl mx-auto lg:mx-4">
        <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl pl-8">
          Shopping Cart
        </h1>
        <div className="w-24 h-1 ml-24 bg-black mt-2 mb-3"></div>
        <form className="lg:grid lg:grid-cols-12 lg:items-start">
          <section aria-labelledby="cart-heading" className="rounded-lg bg-gray-50 mt-2 mb-0 shadow-md lg:col-span-7 p-4 lg:p-6">
            <h2 id="cart-heading" className="sr-only text-black">
              Items in your shopping cart
            </h2>
            <ul role="list" className="divide-y divide-gray-200">
              {cartData.length === 0 ? (
                <li className="py-6 text-center text-gray-500">Your cart is empty.</li>
              ) : (
                cartData.map((product) => (
                  <div key={product._id} className="py-6">
                    <li className="flex sm:py-6 border-b border-gray-200 pb-4">
                      <div className="flex-shrink-0">
                        <img
                          src={`${apiUrl}/${product.images}`}
                          alt={product.name}
                          className="sm:h-38 sm:w-38 h-24 w-24 object-contain object-center"
                        />
                      </div>
                      <div className="ml-4 flex flex-1 flex-col justify-between sm:ml-6">
                        <div className="relative pr-9 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:pr-0">
                          <div>
                            <div className="flex justify-between">
                              <h3 className="text-sm">
                                <a href={product.href} className="font-semibold text-xl text-black">
                                  {product.name}
                                </a>
                              </h3>
                            </div>
                            <div className="mt-1 flex text-sm">
                              <p className="text-base text-gray-500">{product.colors}</p>
                              {product.size && (
                                <p className="ml-4 border-l border-gray-200 pl-4 text-base text-gray-500">
                                  {product.size}
                                </p>
                              )}
                            </div>
                            <div className="mt-1 flex items-end">
                              <p className="text-xl font-medium text-gray-500 line-through">
                                {product.originalPrice}
                              </p>
                              <p className="text-xl font-medium text-gray-900">
                                &nbsp;&nbsp;₹{product.price}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <div className="mb-2 flex items-center space-x-2">
                      <div className="flex items-center space-x-1">
                        <button
                          type="button"
                          className="h-7 w-7 flex items-center justify-center bg-gray-200 rounded-full"
                          onClick={() => updateCartQuantity(product, -1)}
                        >
                          -
                        </button>
                        <input
                          type="text"
                          className="mx-1 h-7 w-12 rounded-md border text-center"
                          value={product.quantity}
                          readOnly
                        />
                        <button
                          type="button"
                          className="h-7 w-7 flex items-center justify-center bg-gray-200 rounded-full"
                          onClick={() => updateCartQuantity(product, 1)}
                        >
                          +
                        </button>
                      </div>
                      <div className="ml-6 flex text-sm">
                        <button
                          type="button"
                          className="flex items-center space-x-1 px-2 py-1 pl-0 text-xs font-medium text-red-500 hover:text-white hover:bg-red-600 rounded-md"
                          onClick={() => handleRemoveItem(product._id)}
                        >
                          <Trash size={12} className="text-current" />
                          <span>Remove</span>
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </ul>
          </section>
          <section
            aria-labelledby="summary-heading"
            className="mt-[-110px] mb-[-40] lg:col-span-4 lg:mt-0 lg:pl-4 lg:pr-4 lg:py-6"
          >
            <div className="p-6 mt-0 mb-0 bg-gray-50 shadow-md rounded-md">
              <h1 className="text-4xl font-bold text-black text-center">Summary</h1>
              <div className="w-24 h-1 mx-auto bg-black mb-4"></div>
              <dl className="space-y-2">
                <div className="flex items-center justify-between">
                  <dt className="text-sm text-gray-800">Price ({itemCount})</dt>
                  <dd className="text-sm font-medium text-gray-900">₹ {subtotal}</dd>
                </div>
                <div className="flex items-center justify-between py-2">
                  <dt className="text-sm text-gray-800">Delivery Charges</dt>
                  <dd className="text-sm font-medium text-green-700">Free</dd>
                </div>
                <div className="flex items-center justify-between border-y border-dashed py-2">
                  <dt className="text-base font-medium text-gray-900">Total Amount</dt>
                  <dd className="text-base font-medium text-gray-900">₹ {subtotal}</dd>
                </div>
              </dl>
              <div className="flex mt-4 space-x-2">
                <Link to="/">
                  <button className="bg-red-500 hover:bg-red-600 rounded px-4 py-2 text-white flex items-center">
                    <i className="fa-solid fa-arrow-left-long mr-2"></i> Shopping
                  </button>
                </Link>
                <Link to="/checktwo">
                  <button className="bg-red-500 hover:bg-red-600 rounded px-4 py-2 text-white">Checkout</button>
                </Link>
              </div>
            </div>
          </section>
        </form>
      </div>
    </div>
  );
}

export default MyCart;
